<template>
  <section class="cash-controller">
    <h2 class="cash-controller__title">{{ $t('PaymentPage.wannaPay') }}</h2>
    <div class="cash-controller__main">
      <section class="cash-controller__main-fields">
        <SharedInputField
          v-model:value="promoInfo.input"
          class="cash-controller__main-fields__promo"
          :placeholder="$t('PaymentPage.promocode')"
          :is-succeed="promoInfo.isSucceed"
          :error="promoInfo.error"
        />
        <PaymentFieldWithSelect
          v-model:value="sumField.value"
          class="cash-controller__main-fields__sum"
          :country="{
            options: modifiedCountries,
            value: selectedCountry,
          }"
          :currency-symbol="
            getIsFake ? Currency.DIAMOND : (currency?.symbol ?? Currency[defaultCurrency as TCurrencyType])
          "
          :error="sumField.error"
          placeholder="0"
          @update:country-value="selectedCountry = $event"
        />
      </section>
      <PaymentSumPresets
        v-if="responsivePresets.length"
        :presets="responsivePresets"
        @preset-select="handlePresetSelect"
      />
    </div>
    <LazyPaymentProgressBar
      v-if="showProgressBar"
      class="cash-controller__progress"
      :items="progressBarData"
      :is-blogger="!!bloggerPromo"
      :sum="sumWithRate"
      :promo="commonPromo"
      @slider-change="updateSumWithRate"
    />
    <section v-if="bloggerRewards" class="cash-controller__slider">
      <div v-for="reward in bloggerRewards" :key="reward.bonus.from" class="cash-controller__slider-item">
        <LazyPaymentBloggerUpgrade
          :price="reward.bonus.from"
          :coins="reward.bonus.coins"
          :case-data="reward.bonus.case"
          :is-active="reward.isActive"
          :is-disabled="reward.isDisabled"
          :coin-icon="coinIcon"
          @apply="handleBloggerUpgradeApply(reward.bonus.from)"
        />
      </div>
    </section>
  </section>
</template>

<script setup lang="ts">
import { useCountriesStore } from '~/features/payment/store/countries.store';
import { useSumPresetStore } from '~/features/payment/store/sumPreset.store';
import { useSumStore } from '~/features/payment/store/sum.store';
import { useCurrencyStore } from '~/features/payment/store/currency.store';
import { useBonusesStore } from '~/features/payment/store/bonuses.store';
import { usePromoStore } from '~/features/payment/store/promo.store';
import { useCountryStore } from '~/features/payment/store/country.store';
import { useEventStore } from '~/store/event/event.store';
import { useUserStore } from '~/store/user/user.store';

import type { ISumPresetSelectParameter } from '~/features/payment/components/SumPresets/SumPresets.types';

import { Currency } from '~/constants/currency.constants';
import { PaymentEvents } from '~/repository/amplitude/events/payment';
import type { TCurrencyType } from '~/types/Shared.types';

const { defaultCurrency } = useProjectSettings();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const countriesStore = useCountriesStore();
const { countries } = storeToRefs(countriesStore);

const countryStore = useCountryStore();
const { selectedCountry } = storeToRefs(countryStore);

const sumPresetStore = useSumPresetStore();
const { presets } = storeToRefs(sumPresetStore);

const sumStore = useSumStore();
const { sumField, sumWithRate } = storeToRefs(sumStore);

const currencyStore = useCurrencyStore();
const { currency } = storeToRefs(currencyStore);

const promoStore = usePromoStore();
const { promoInfo, commonPromo, bloggerPromo, bloggerRewards } = storeToRefs(promoStore);

const bonusesStore = useBonusesStore();
const { bonuses } = storeToRefs(bonusesStore);

const eventStore = useEventStore();
const { coinIcon } = storeToRefs(eventStore);

const userStore = useUserStore();
const { getIsFake } = storeToRefs(userStore);

const responsivePresets = computed(() => changeXL(presets.value.desktop, presets.value.mobile));

const progressBarData = computed(() => {
  if (bloggerPromo.value) {
    return bloggerPromo.value.map((bonus) => ({
      from: bonus.from,
      percent: undefined,
      topInfo: bonus.case?.image ?? '',
    }));
  }

  return bonuses.value.map((item) => ({
    from: item.from,
    percent: item.percent,
    topInfo: `+ ${item.percent}%`,
  }));
});

const { t } = useI18n();

const modifiedCountries = computed(() => {
  return countries.value.map((country) => {
    if (country.label === 'custom') {
      return {
        ...country,
        label: t('PaymentPage.other'),
      };
    }
    return country;
  });
});

const showProgressBar = computed(() => {
  if (bloggerPromo.value || !commonPromo.value) return true;
  return progressBarData.value.some((item) => item.percent && item.percent > commonPromo.value);
});

const handleBloggerUpgradeApply = (value: number) => {
  PaymentEvents.dealClicked();
  updateSumWithRate(value);
};

const updateSumWithRate = (value: number) => {
  const valueWithRate = Math.ceil(value * (currency.value?.rate ?? 1));
  sumField.value.value = `${valueWithRate}`;
};

const handlePresetSelect = (data: ISumPresetSelectParameter) => {
  PaymentEvents.presetButtonClicked({ 'Button Value': data.preset });
  sumField.value.value = `${data.preset}`;
};
</script>

<style scoped lang="scss" src="./CashController.scss" />
