<template>
  <div class="right-side" :style="styleVariables">
    <div class="right-side__wrapper"></div>
    <div class="right-side__body">
      <PaymentInstantController v-if="instantPromo" />
      <div v-else-if="isClassicMethod" class="right-side__body-cash">
        <PaymentCashController />
        <PaymentCashPreviewController />
      </div>
      <PaymentSkinsController v-else />
      <PaymentPayBlock
        v-if="changeMD(true, false)"
        v-model:is-checked="isAgreementChecked"
        class="right-side__body-pay"
        :pay-type="payBlockPayType"
        :type="payBlockType"
        :sum="sum"
        :is-loading="isLoading"
        :currency-symbol="
          getIsFake ? Currency.DIAMOND : (currency?.symbol ?? Currency[defaultCurrency as TCurrencyType])
        "
        :is-instant="!!instantPromo"
        :is-valid-fields="allFieldsValid"
        @start="payHandler"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { EPayBlockPayTypes, EPayBlockTypes } from '~/features/payment/components/PayBlock/PayBlock.types';

import { PaymentColors } from '~/features/payment/constants/colors.constants';
import { Colors } from '~/constants/colors.constants';

import { useAdditionalFieldsStore } from '~/features/payment/store/additionalFields.store';
import { useMethodsStore } from '~/features/payment/store/methods.store';
import { useSumStore } from '~/features/payment/store/sum.store';
import { useCurrencyStore } from '~/features/payment/store/currency.store';
import { usePromoStore } from '~/features/payment/store/promo.store';
import { useUserStore } from '~/store/user/user.store';

import { useResultsStore } from '~/features/payment/store/results.store';
import { EResultSuccessTypes, EResultTypes } from '~/features/payment/types/result/client.types';
import { useTransactionStore } from '~/features/payment/store/transaction.store';

import { Currency } from '~/constants/currency.constants';

import { EAmplitudeEvents, useAmplitudeTransaction } from '~/features/payment/composables/useAmplitudeTransaction';
import { PaymentEvents } from '~/repository/amplitude/events/payment';
import type { IPaymentPromoActivatedEvent } from '~/repository/amplitude/types/payment.types';
import type { TCurrencyType } from '~/types/Shared.types';
import { useAlertStore } from '~/store/alert/alert.store';

const { defaultCurrency } = useProjectSettings();

const payBlockPayType = computed(() => {
  if (instantPromo.value) return EPayBlockPayTypes.INSTANT;
  if (isClassicMethod.value) return EPayBlockPayTypes.CASH;
  return EPayBlockPayTypes.SKINS;
});
const payBlockType = computed(() => (bloggerPromo.value ? EPayBlockTypes.BLOGGER : EPayBlockTypes.COMMON));

const changeMD = GlobalUtils.Media.changeByMedia('md');

const additionalFieldsStore = useAdditionalFieldsStore();
const { fieldsValid } = storeToRefs(additionalFieldsStore);

const methodsStore = useMethodsStore();
const { selectedMethod, isClassicMethod } = storeToRefs(methodsStore);

const sumStore = useSumStore();
const { sum, sumField, isAgreementChecked } = storeToRefs(sumStore);

const currencyStore = useCurrencyStore();
const { currency } = storeToRefs(currencyStore);

const promoStore = usePromoStore();
const { promoInfo, commonPromo, bloggerPromo, instantPromo } = storeToRefs(promoStore);

const resultStore = useResultsStore();
const { result } = storeToRefs(resultStore);

const userStore = useUserStore();
const { getIsFake } = storeToRefs(userStore);

const transactionStore = useTransactionStore();
const { isLoading } = storeToRefs(transactionStore);

const amplitudeTransaction = useAmplitudeTransaction(EAmplitudeEvents.PAYMENT_STARTED);

const { t } = useI18n();

const warningTimings = {
  first: 10000,
  second: 20000,
};

const allFieldsValid = computed(() => {
  if (instantPromo.value) return true;
  return !!selectedMethod.value && fieldsValid.value && !sumField.value.error;
});

const styleVariables = computed(() => {
  if (bloggerPromo.value) {
    return {
      '--right-side-color': GlobalUtils.CSS.getBackgroundColor({
        colorStops: PaymentColors.PAYMENT_GRADIENT_LIGHTEN,
        toDirection: '113deg',
      }),
      '--right-side-shadow': '6px 0px 60px -26px #8e60ee, 0px 4px 60px -22px #dd22ad',
    };
  }

  return {
    '--right-side-color': GlobalUtils.CSS.getBackgroundColor(Colors.MAIN.PURPLE_800),
    '--right-side-shadow': '-6px -4px 30px -16px rgba(81, 42, 150, 0.30), 0px 4px 30px -13px #8E60EE',
  };
});

const amplitudePromoInfo = computed(() => {
  let promoType = 'no_promo';

  if (commonPromo.value) promoType = 'common';
  if (bloggerPromo.value) promoType = 'bloger';
  if (instantPromo.value) promoType = 'instant';

  const result: IPaymentPromoActivatedEvent = { promoType };

  if (promoType !== 'no_promo') {
    result.promoName = promoInfo.value.input;
  }

  return result;
});

const payHandler = async () => {
  PaymentEvents.promoActivated(amplitudePromoInfo.value);

  if (instantPromo.value) {
    const success = await promoStore.applyInstantPromo();
    if (!success) {
      return;
    }

    result.value = {
      status: EResultTypes.SUCCESS,
      sum: instantPromo.value,
      type: EResultSuccessTypes.COMMON,
    };

    return navigateTo(ROUTING.PAYMENT.RESULT.path);
  }

  let startTransactionThrottle = setTimeout(() => {
    useAlertStore().show({
      title: t('PaymentPage.waitSomeMore'),
      type: 'success',
    });
    startTransactionThrottle = setTimeout(() => {
      useAlertStore().show({
        title: t('PaymentPage.errorOccurredTryAgain'),
        type: 'error',
      });
      isLoading.value = false;
    }, warningTimings.second);
  }, warningTimings.first);

  await transactionStore.startTransaction();

  if (startTransactionThrottle) clearTimeout(startTransactionThrottle);

  if (transactionStore.redirectUrl) {
    amplitudeTransaction.send();
    return navigateTo(transactionStore.redirectUrl, { external: true });
  }
};
</script>

<style src="./RightSideController.scss" scoped lang="scss"></style>
